import React from "react";
import "./faq.css";
import { Accordion } from "react-bootstrap";

const Faq = () => {
  const data = [
    {
      id: 1,
      question: "QUESTION",
      paragraph:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
    },

    {
      id: 2,
      question: "QUESTION",
      paragraph:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
    },
    {
      id: 3,
      question: "QUESTION",
      paragraph:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
    },
    {
      id: 4,
      question: "QUESTION",
      paragraph:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
    },
    {
      id: 5,
      question: "QUESTION",
      paragraph:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
    },
  ];
  return (
    <>
      <div className="faq_section">
        <h1 className="faq_heading">
          <b>FAQ</b>
        </h1>
        <div className="faq_detail">
          <div className="faq_card">
            {" "}
            <Accordion defaultActiveKey="0">
              {data.map((data, index) => {
                return (
                  <div className="acc_row" key={data.id}>
                    <Accordion.Item eventKey={data.id} className="acc_section">
                      <Accordion.Header>
                        <div className="que_row">
                          <h4 className="que_text">{data.question}</h4>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="faq_desc">{data.paragraph}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                );
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;

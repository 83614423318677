import React from "react";
import "../Footer/footer.css";
import Logo from "../../assets/images/logo.png";
import Discord from "../../assets/images/discord.png";
import Twitter from "../../assets/images/twitter.png";
import Instagram from "../../assets/images/insta.png";

const Footer = () => {
  return (
    <>
      <div className="footer_wrapper">
        <div className="footer_main">
          <div className="footer_logo_wrapper">
            <div>
              <img alt="alter" src={Logo} />
            </div>
            <div>
              <div className="footer_social_link">
                <div className="footer_s_link_wrapper shadow">
                  <img alt="alter" src={Instagram} />
                </div>
                <div className="footer_s_link_wrapper shadow">
                  <img alt="alter" src={Twitter} />
                </div>
                <div className="footer_s_link_wrapper shadow">
                  <img alt="alter" src={Discord} />
                </div>
              </div>
            </div>
          </div>
          <div className="copy_right_section">
            <div>
              {" "}
              &copy; 2022 vibecheck{" "}
              <span className="all_r">All Rights Reserved</span>
            </div>
            <div className="footer_right_sect">
              <div>Terms & Condition</div>
              <div>Legal</div>
              <div>Contact</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

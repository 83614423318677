import React from "react";
import Logo from "../../assets/images/logo.png";
import "../Header/header.css";
import Instagram from "../../assets/images/insta.png";
import Twitter from "../../assets/images/twitter.png";
import Discord from "../../assets/images/discord.png";

const Header = () => {
  return (
    <>
      <div className="main_container">
        <nav class="navbar navbar-expand-lg navbar-light nav_baground">
          <a class="navbar-brand" href="#">
            <img src={Logo} />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarText">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item active">
                <a class="nav-link nav_item_c" href="#">
                  STORY <span class="sr-only">(current)</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link nav_item_c" href="#">
                  ROADMAP
                </a>
              </li>
              {/* <li class="nav-item">
                                <a class="nav-link nav_item_c" href="#">TEAMS</a>
                            </li> */}
              <li class="nav-item">
                <a class="nav-link nav_item_c" href="#">
                  CONTACTS
                </a>
              </li>
            </ul>
            {/* <span class="navbar-text">
                            Navbar text with an inline element
                        </span> */}
            <div className="nav_right_section">
              <div className="nav_social_links">
                <div className="social_link_box">
                  <img alt="altr" src={Instagram} />
                </div>
                <div className="social_link_box">
                  <img alt="altr" src={Twitter} />
                </div>
                <div className="social_link_box">
                  <img alt="altr" src={Discord} />
                </div>
              </div>

              {/* <div className='sub_domain'>VIEW SUBDOMAIN</div> */}
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;

import React from "react";
import Header from "../Header";
import FAQ from "../FAQ/Faq";
import Footer from "../Footer";
import "./landingpage.css";
import G1 from "../../assets/images/gallery1.png";
import G2 from "../../assets/images/gallery2.png";
import G3 from "../../assets/images/gallery3.png";
import VC from "../../assets/images/vc.png";
import BD from "../../assets/images/bd.png";
import Gp1 from "../../assets/images/g1.png";
import Gp2 from "../../assets/images/g2.png";
import Gp3 from "../../assets/images/g3.png";
import Gp11 from "../../assets/images/g11.png";
import Gp22 from "../../assets/images/g22.png";
import Gp33 from "../../assets/images/g33.png";
import Gp111 from "../../assets/images/g4.png";
import Gp222 from "../../assets/images/g5.png";
import Gp333 from "../../assets/images/g6.png";
import Gp31 from "../../assets/images/g10.png";

const LandingPage = () => {
  return (
    <>
      <div className="navbar_wrapper">
        <Header />
      </div>
      {/* <=========Collection Section=======> */}
      <div className="body_wrapper">
        <div className="main_collection">
          <div className="d-flex justify-content-center ">
            <div className="collection">The Collecton</div>
          </div>
          <div className="d-flex justify-content-center ">
            <div className="collection_text">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </div>
          </div>
          <div className="collection_img_wrapper">
            <div className="collection_img_inner">
              <div>
                <img alt="altenative" src={G1} className="img-fluid c_img" />
              </div>
              <div>
                <img alt="altenative" src={G2} className="img-fluid c_img" />
              </div>
              <div>
                <img alt="altenative" src={G3} className="img-fluid c_img" />
              </div>
            </div>
          </div>
          {/* <=========Mission Section=======> */}
          <div className="d-flex justify-content-center ">
            <div className="Mission">Mission</div>
          </div>
          <div className="collection_text">
            {" "}
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
            Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
            sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
          </div>

          <div className="vc_img_sec">
            <img alt="altenative" src={VC} className="img-fluid" />
          </div>
          {/* ............Join Us On Discord........... */}
          <div className="d-flex justify-content-center">
            <div className="join_wrapper">
              <div className="join_text">JOIN US ON DISCORD</div>
              <div className="bd">
                <img alt="altenative" src={BD} />
              </div>
            </div>
          </div>

          {/* ...........Instagram gallery.......... */}
          <div className="d-flex justify-content-center ">
            <div className="Mission">#Instagram</div>
          </div>
          <div className="gallery_wrapper">
            <div>
              <div className="d-flex">
                <div>
                  <img
                    alt="altenative"
                    src={Gp1}
                    className="img-fluid g_img_h"
                  />
                </div>
                <div>
                  <img
                    alt="altenative"
                    src={Gp2}
                    className="img-fluid g_img_h"
                  />
                </div>
                <div>
                  <img
                    alt="altenative"
                    src={Gp3}
                    className="img-fluid g_img_h"
                  />
                </div>
              </div>
              <div className="d-flex">
                <div>
                  <img
                    alt="altenative"
                    src={Gp11}
                    className="img-fluid g_img_h"
                  />
                </div>
                <div>
                  <div>
                    <img src={Gp22} className="img-fluid g_img_h" />
                  </div>
                  <div>
                    <img src={Gp33} className="img-fluid g_img_h" />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex">
                <div>
                  <img src={Gp111} className="img-fluid g_img_h" />
                </div>
                <div>
                  <div>
                    <img src={Gp222} className="img-fluid g_img_h" />
                  </div>
                  <div>
                    <img src={Gp333} className="img-fluid g_img_h" />
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div>
                  <img src={Gp1} className="img-fluid g_img_h" />
                </div>
                <div>
                  <img src={Gp2} className="img-fluid g_img_h" />
                </div>
                <div>
                  <img src={Gp31} className="img-fluid g_img_h" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Team /> */}
        <FAQ />
      </div>

      <Footer />
    </>
  );
};

export default LandingPage;
